import logo from "./logo.svg";
import "./App.css";
import Home from "./Pages/Home/Home";
import { Routes, Route } from "react-router-dom";
import Contact from "./Pages/Contact/Contact";
import OneTour from "./Pages/OneTour/OneTour";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/:id" element={<OneTour />} />
      </Routes>
    </>
  );
}

export default App;
