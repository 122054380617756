import React from "react";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <>
      <footer className="footer clearfix" >
        <div className="container">
          {/* First footer */}
          <div className="first-footer">
            <div className="row">
              <div className="col-md-12">
                <div className="links dark footer-contact-links" style={{borderRadius: '8px'}}>
                  <div className="footer-contact-links-wrapper" >
                    <div className="footer-contact-link-wrapper">
                      <div className="image-wrapper footer-contact-link-icon">
                        <div className="icon-footer">
                          <i class="fa-solid fa-phone"></i>
                        </div>
                      </div>
                      <div className="footer-contact-link-content">
                        <h6>{t("call")}</h6>
                        <p>+998900245554</p>
                      </div>
                    </div>
                    <div className="footer-contact-links-divider" />
                    <div className="footer-contact-link-wrapper">
                      <div className="image-wrapper footer-contact-link-icon">
                        <div className="icon-footer">
                          <i class="fa-solid fa-message"></i>
                        </div>
                      </div>
                      <div className="footer-contact-link-content">
                        <h6>{t("write")}</h6>
                        <p>brighttour.uz@gmail.com </p>
                      </div>
                    </div>
                    <div className="footer-contact-links-divider" />
                    <div className="footer-contact-link-wrapper">
                      <div className="image-wrapper footer-contact-link-icon">
                        <div className="icon-footer">
                          {" "}
                          <i class="fa-solid fa-location-dot"></i>
                        </div>
                      </div>
                      <div className="footer-contact-link-content">
                        <h6>Address</h6>
                        <p>Sandiqli ko'chasi 29A uy</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Second footer */}
          <div className="second-footer">
            {/* <div className="row"> */}
            {/* about & social icons */}
            {/* <div className="col-md-4 widget-area">
                  <div className="widget clearfix">
                    <div className="footer-logo">
                      {" "}
                      <img
                        className="img-fluid"
                        src="img/logo-light.png"
                        alt=""
                      />{" "}
                    </div>
                    <div className="widget-text">
                      <p>
                        Quisque imperdiet sapien porttito the bibendum
                        sellentesque the commodo erat acar accumsa lobortis,
                        enim diam the nesuen.
                      </p>
                      <div className="social-icons">
                        <ul className="list-inline">
                          <li>
                            <a href="#">
                              <i className="ti-instagram" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="ti-twitter" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="ti-facebook" />
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i className="ti-youtube" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div> */}
            {/* quick links */}
            {/* <div className="col-md-3 offset-md-1 widget-area">
                  <div className="widget clearfix usful-links">
                    <h3 className="widget-title">Quick Links</h3>
                    <ul>
                      <li>
                        <a href="about.html">About</a>
                      </li>
                      <li>
                        <a href="tours.html">Tours</a>
                      </li>
                      <li>
                        <a href="destination.html">Destinations</a>
                      </li>
                      <li>
                        <a href="contact.html">Contact</a>
                      </li>
                    </ul>
                  </div>
                </div> */}
            {/* subscribe */}
            {/* <div className="col-md-4 widget-area">
                  <div className="widget clearfix">
                    <h3 className="widget-title">Subscribe</h3>
                    <p>
                      Sign up for our monthly blogletter to stay informed about
                      travel and tours
                    </p>
                    <div className="widget-newsletter">
                      <form action="#">
                        <input
                          type="email"
                          placeholder="Email Address"
                          required
                        />
                        <button type="submit">Send</button>
                      </form>
                    </div>
                  </div>
                </div> */}
            {/* </div> */}
          </div>
          {/* Bottom footer */}
          <div className="bottom-footer-text">
            <div className="row copyright">
              <div className="col-md-12">
                <p className="mb-0 text-center">
                  ©2023 <a href="/">Brighttour</a>. {t("rights")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
