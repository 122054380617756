import { useTranslation } from "react-i18next";
import React from "react";
import Footer from "../../Components/Footer/Footer";
import Nav from "../../Components/Navnew/Nav";

const Contact = () => {
  const { t, i18n } = useTranslation();
  const formBtn = (e) => {
    e.preventDefault();
    if (e.target[0].value.length > 0 && e.target[1].value.length > 0) {
      let botMessege = `
Salom Brighttour Admin, Yangi Xabar!😊%0A
Ismi    👤: ${e.target[0].value}%0A
Email   📧: ${e.target[1].value}%0A               
                `;

      let url = `https://api.telegram.org/bot6373173082:AAFtQIPPcmxBVfYerseJTW524ZhTiKdD1H8/sendMessage?chat_id=-912320303&text=${botMessege}`;
      async function fetchAsync(url) {
        let response = await fetch(url);
        let data = await response.json();
        return data;
      }
      fetchAsync(url);
      e.target[0].value = "";

      e.target[1].value = "";
    } else {
      return -1;
    }
  };
  return (
    <>
      <Nav />
      <div
        className="container"
        style={{ marginTop: "5rem", marginTop: "15rem" }}
      >
        <div className="row">
          <div className="col d-flex flex-wrap">
            <div className="card col-md-8 col-sm-12 p-0">
              <div
                className="card-header text-white"
                style={{ backgroundColor: "#023f57" }}
              >
                <i className="fa fa-envelope" /> {t("cus")}
                <div className="card-body">
                  <form onSubmit={formBtn}>
                    <div className="form-group">
                      <label htmlFor="name">{t("name")}</label>
                      <input
                        type="text"
                        className="form-control p-3"
                        id="name"
                        aria-describedby="emailHelp"
                        placeholder={t("name")}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="email">{t("email")}</label>
                      <input
                        type="email"
                        className="form-control p-3"
                        id="email"
                        aria-describedby="emailHelp"
                        placeholder={t("email")}
                        required
                      />
                    </div>

                    <div className="mx-auto">
                      <button
                        type="submit"
                        className="submit-button"
                        style={{
                          backgroundColor: "#023f57",
                          paddingLeft: "15px",
                          paddingRight: "15px",
                          paddingTop: "6px",
                          paddingBottom: "6px",
                          borderRadius: "10px",
                          borderBlockColor: "white",
                        }}
                      >
                        {t("submit")}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="card col-md-4 col-sm-12 p-0">
              <div className="card bg-light mb-3">
                <div
                  className="card-header  text-white text-uppercase"
                  style={{ backgroundColor: "#023f57" }}
                >
                  <i className="fa fa-home" /> {t("address")}
                </div>
                <div className="card-body">
                  <p>Sandiqli ko'chasi 29A uy</p>
                  <p>Tashkent</p>
                  <p>Uzbekistan</p>
                  <p>Email : brighttour.uz@gmail.com </p>
                  <p>Tel. +998900245554</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            position: "relative",
            overflow: "hidden",
            marginTop: "1rem",
          }}
        >
          <a
            href="https://yandex.uz/maps/10335/tashkent/?utm_medium=mapframe&utm_source=maps"
            style={{
              color: "#eee",
              fontSize: "12px",
              position: "absolute",
              top: "0px",
            }}
          >
            Ташкент
          </a>
          <a
            href="https://yandex.uz/maps/10335/tashkent/?from=mapframe&ll=69.192011%2C41.290525&mode=routes&rtext=~41.290573%2C69.190847&rtt=auto&ruri=~&source=mapframe&utm_medium=mapframe&utm_source=maps&z=17.25"
            style={{
              color: "#eee",
              fontSize: "12px",
              position: "absolute",
              top: "14px",
            }}
          >
            Яндекс Карты
          </a>
          <iframe
            src="https://yandex.uz/map-widget/v1/-/CCUnvXdqPA"
            width="560"
            height="400"
            allowfullscreen="true"
            style={{ position: "relative" }}
            className="iframe1"
          ></iframe>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default Contact;
